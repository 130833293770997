import React from "react";
import { motion } from "framer-motion";

const PsychosisDisorder = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 font-sans leading-relaxed">
      <motion.h1
        className="text-center text-3xl font-bold text-gray-800 mb-6"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        Understanding Psychosis Disorder: Causes and Prevention
      </motion.h1>
      <section>
        <motion.h2
          className="text-2xl font-semibold text-gray-700 mt-4"
          initial={{ x: -100 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.5 }}
        >
          What is Psychosis?
        </motion.h2>
        <p className="text-gray-600 mt-2">
          Psychosis is a mental health condition that affects how a person
          thinks, feels, and behaves. People with psychosis may experience
          hallucinations (hearing or seeing things that aren’t there), delusions
          (false beliefs), and disorganized thinking. It is often associated
          with conditions like schizophrenia, bipolar disorder, or severe
          depression.
        </p>
      </section>
      <section>
        <motion.h2
          className="text-2xl font-semibold text-gray-700 mt-4"
          initial={{ x: -100 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.5 }}
        >
          Why is Psychosis Becoming More Common?
        </motion.h2>
        <ul className="list-disc list-inside text-gray-600 mt-2">
          <li>
            <strong>Increased Awareness:</strong> There's less stigma now, which
            means more people seek help and are diagnosed.
          </li>
          <li>
            <strong>Stress and Trauma:</strong> Modern life stressors, including
            social isolation, economic instability, and personal trauma, may
            contribute to the rise in mental health issues.
          </li>
          <li>
            <strong>Substance Abuse:</strong> The use of drugs like cannabis,
            hallucinogens, or stimulants can trigger or worsen psychosis.
          </li>
          <li>
            <strong>Genetic Factors:</strong> A family history of mental illness
            increases the risk of developing psychotic disorders.
          </li>
        </ul>
      </section>
      <section>
        <motion.h2
          className="text-2xl font-semibold text-gray-700 mt-4"
          initial={{ x: -100 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.5 }}
        >
          Role of Diet and Lifestyle
        </motion.h2>
        <ul className="list-disc list-inside text-gray-600 mt-2">
          <li>
            <strong>Unhealthy Diet:</strong> Diets high in processed foods,
            sugars, and unhealthy fats can affect brain function and mood
            regulation.
          </li>
          <li>
            <strong>Lack of Exercise:</strong> Regular physical activity is
            vital for brain health and can reduce stress, which may trigger
            psychotic episodes.
          </li>
          <li>
            <strong>Sleep Disorders:</strong> Chronic lack of sleep or irregular
            sleep patterns can exacerbate mental health issues.
          </li>
        </ul>
      </section>
      <section>
        <motion.h2
          className="text-2xl font-semibold text-gray-700 mt-4"
          initial={{ x: -100 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.5 }}
        >
          Nutritional Deficiencies and Psychosis
        </motion.h2>
        <ul className="list-disc list-inside text-gray-600 mt-2">
          <li>
            <strong>Vitamin D Deficiency:</strong> Low levels of Vitamin D have
            been linked to depression, mood disorders, and psychotic symptoms.
          </li>
          <li>
            <strong>B-Vitamins:</strong> Deficiencies in B12 and folate can
            cause neurological and psychiatric symptoms, including psychosis.
          </li>
          <li>
            <strong>Omega-3 Fatty Acids:</strong> Low levels of omega-3s, found
            in fish, can impact brain function and increase the risk of mental
            disorders.
          </li>
        </ul>
      </section>
      <section>
        <motion.h2
          className="text-2xl font-semibold text-gray-700 mt-4"
          initial={{ x: -100 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.5 }}
        >
          How to Prevent Psychosis through Diet and Lifestyle
        </motion.h2>
        <ul className="list-disc list-inside text-gray-600 mt-2">
          <li>
            <strong>Balanced Diet:</strong> Eat a variety of nutrient-dense
            foods, including fruits, vegetables, whole grains, lean proteins,
            and healthy fats.
          </li>
          <li>
            <strong>Vitamin D:</strong> Ensure adequate sun exposure or consider
            a Vitamin D supplement if necessary.
          </li>
          <li>
            <strong>B-Vitamins:</strong> Include sources like leafy greens,
            eggs, and fortified cereals in your diet.
          </li>
          <li>
            <strong>Omega-3s:</strong> Consume fatty fish (like salmon), nuts,
            and seeds to support brain health.
          </li>
          <li>
            <strong>Exercise Regularly:</strong> Aim for at least 30 minutes of
            physical activity most days.
          </li>
          <li>
            <strong>Adequate Sleep:</strong> Prioritize 7-9 hours of restful
            sleep each night.
          </li>
        </ul>
      </section>
      <section>
        <motion.h2
          className="text-2xl font-semibold text-gray-700 mt-4"
          initial={{ x: -100 }}
          animate={{ x: 0 }}
          transition={{ duration: 0.5 }}
        >
          Conclusion
        </motion.h2>
        <p className="text-gray-600 mt-2">
          While psychosis can be complex and multifactorial, maintaining a
          healthy diet, staying physically active, and managing stress are
          essential steps in reducing the risk of developing psychotic
          disorders. Consult with a healthcare professional for personalized
          advice and treatment.
        </p>
      </section>
    </div>
  );
};

export default PsychosisDisorder;
