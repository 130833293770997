import React from "react";
import { useNavigate } from "react-router-dom";

const blog = [
  {
    category: "Hypertension & Diabetic Diet",
    title: "Managing Hypertension and Diabetes Through Diet",
    text: "Learn how specific dietary choices can help manage hypertension and diabetes.",
    img: "/img/programs/p1.jpg",
    link: "/HypertensionDiabeticDiet",
  },
  {
    category: "Thyroid Disease Diet",
    title: "Diet for Thyroid Disease",
    text: "Discover how a balanced diet can support thyroid health.",
    img: "/img/programs/p2.jpg",
    link: "/ThyroidDiseaseDiet",
  },
  {
    category: "PCOD & Obesity Diet",
    title: "Effective Diet Plans for PCOD and Obesity",
    text: "Explore nutritional strategies for managing PCOD and obesity.",
    img: "/img/programs/p3.jpg",
    link: "/PCODandObesityDiet",
  },
  {
    category: "Diet for Low Immunity",
    title: "Boost Your Immunity with the Right Diet",
    text: "Essential dietary tips to enhance your immune system.",
    img: "/img/programs/p4.jpg",
    link: "/DietForLowImmunity",
  },
  {
    category: "Psychosis Disorder",
    title: "Psychosis Disorder: Causes and Prevention",
    text: "Learn about the key causes of psychosis disorder and effective prevention strategies to maintain mental health.",
    img: "/img/programs/p5.jpg",
    link: "/PsychosisDisorder",
  },
  {
    category: "Arthritis & Bone Health Diet",
    title: "Dietary Tips for Arthritis and Bone Health",
    text: "Ensure your bones stay strong and manage arthritis through diet.",
    img: "/img/programs/p6.jpg",
    link: "/ArthritisAndBoneHealthDiet",
  },
  {
    category: "Pregnancy and Lactation Diet",
    title: "Dietary Guidance for Pregnancy and Lactation",
    text: "Nutritional advice to support pregnancy and lactation.",
    img: "/img/programs/p7.jpg",
    link: "/PregnancyAndLactationDiet",
  },
  {
    category: "Child Health Diet + Obesity",
    title: "Balanced Diet for Child Health and Obesity Prevention",
    text: "Nutritional plans to ensure your child growth and prevent obesity.",
    img: "/img/programs/p8.jpg",
    link: "/ChildHealthDiet",
  },
  {
    category: "Vitamin Therapy - Vitamin Deficiency",
    title: "Addressing Vitamin Deficiencies with Diet",
    text: "Identify and manage vitamin deficiencies through proper nutrition.",
    img: "/img/programs/p9.jpg",
    link: "/VitaminTherapy",
  },
  {
    category: "Uric Acid Arthritis",
    title: "Dietary Management of Uric Acid Arthritis",
    text: "Learn how to control uric acid levels through diet.",
    img: "/img/programs/p10.png",
    link: "/UricAcidArthritis",
  },
  {
    category: "Gut Cleaning Program",
    title: "Cleansing Your Gut with the Right Foods",
    text: "Find out how to improve your gut health through diet.",
    img: "/img/programs/p11.jpg",
    link: "/GutCleaningProgram",
  },
  {
    category: "Heart Diet",
    title: "Heart-Healthy Diet Tips",
    text: "Essential dietary advice to maintain a healthy heart.",
    img: "/img/programs/p12.jpg",
    link: "/HeartDiet",
  },
];

const OurPrograms = () => {
  const navigate = useNavigate();

  const handleClick = (link) => {
    navigate(link);
  };

  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-4 mx-auto mt-10">
        <div className="flex flex-col text-center w-full mb-10">
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
            Our Programs
          </h1>
          <h2 className="text-sm font-medium title-font text-gray-900 md:mx-32">
            Programs tailored to meet your every dietician need.
          </h2>
        </div>
        <div className="flex flex-wrap -m-4">
          {blog.map((item, index) => (
            <div
              className="p-4 md:w-1/3 cursor-pointer"
              key={index}
              onClick={() => handleClick(item.link)} // Add onClick event
            >
              <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                <img
                  className="lg:h-60 md:h-36 w-full object-cover object-center"
                  src={item.img}
                  alt="blog"
                />
                <div className="p-6">
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                    {item.category}
                  </h2>
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                    {item.title}
                  </h1>
                  <p className="leading-relaxed mb-3">{item.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurPrograms;
