import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Footer from "./components/footer/Footer";
// import Team from "./Pages/Team";
import Process from "./Pages/Process";
import Prices from "./Pages/Prices";
import Blogs from "./Pages/Blogs";
import Contact from "./Pages/Contact";
import NavBar from "./components/nav/Navbar";
import BMICalculator from "./components/Bmi";
import BookAppointment from "./Pages/Appointment";
import Gallery from "./Pages/Gallery";
import Login from "./components/admin/Login";
import AdminBlogsPanel from "./components/admin/AdminBlogs";
import PrivateRoute from "./components/admin/PrivateRoute";
import AdminContacts from "./components/admin/AdminContact";
import AdminAppointments from "./components/admin/AdminAppointments";
import OnlineYogaClass from "./Pages/YogaProgram";
import DietPlanPage from "./Pages/DietPlan";
import HypertensionDiabeticDiet from "./components/Programs/HypertensionDiabeticDiet";
import ThyroidDiseaseDiet from "./components/Programs/ThyroidDiseaseDiet";
import PCODandObesityDiet from "./components/Programs/PCODandObesityDiet";
import PregnancyAndLactationDiet from "./components/Programs/PregnancyAndLactationDiet";
import VitaminTherapy from "./components/Programs/VitaminTherapy";
import ChildHealthDiet from "./components/Programs/ChildHealthDiet";
import HeartDiet from "./components/Programs/HeartDiet";
import DietForLowImmunity from "./components/Programs/DietForLowImmunity";
import ArthritisAndBoneHealthDiet from "./components/Programs/ArthritisAndBoneHealthDiet";
import UricAcidArthritis from "./components/Programs/UricAcidArthritis";
import AnemiaDiet from "./components/Programs/AnemiaDiet";
import GutCleaningProgram from "./components/Programs/GutCleaningProgram";
import AdminPlan from "./components/admin/AdminPlans";
import UpdateAdminCredentials from "./components/admin/UpdateAdminCredentials";
import AdminSpecialOffers from "./components/admin/AdminSpecialOffers";
import PrivacyPolicy from "./Pages/Privacy";
import CancellationRefundPolicy from "./Pages/CancellationRefundPolicy";
import TermsOfService from "./Pages/TermsOfService";
import Support from "./Pages/Support";
import PsychosisDisorder from "./components/Programs/PsychosisDisorder";

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/bmicalculator" element={<BMICalculator />} />
        <Route path="/about" element={<About />} />
        <Route path="/OnlineYogaProgram" element={<OnlineYogaClass />} />
        <Route path="/DeitPlans" element={<DietPlanPage />} />
        <Route
          path="/HypertensionDiabeticDiet"
          element={<HypertensionDiabeticDiet />}
        />
        <Route path="/PCODandObesityDiet" element={<PCODandObesityDiet />} />
        <Route
          path="/PregnancyAndLactationDiet"
          element={<PregnancyAndLactationDiet />}
        />
        <Route path="/ThyroidDiseaseDiet" element={<ThyroidDiseaseDiet />} />
        <Route path="/PsychosisDisorder" element={<PsychosisDisorder />} />
        <Route path="/VitaminTherapy" element={<VitaminTherapy />} />
        <Route path="/ChildHealthDiet" element={<ChildHealthDiet />} />
        <Route path="/DietForLowImmunity" element={<DietForLowImmunity />} />
        <Route path="/GutCleaningProgram" element={<GutCleaningProgram />} />
        <Route path="/HeartDiet" element={<HeartDiet />} />
        <Route
          path="/ArthritisAndBoneHealthDiet"
          element={<ArthritisAndBoneHealthDiet />}
        />
        <Route path="/UricAcidArthritis" element={<UricAcidArthritis />} />
        <Route path="/AnemiaDiet" element={<AnemiaDiet />} />
        <Route path="/appointment" element={<BookAppointment />} />
        {/* <Route path="/team" element={<Team />} /> */}
        <Route path="/process" element={<Process />} />
        <Route path="/pricing" element={<Prices />} />
        <Route path="/Gallery" element={<Gallery />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsofservice" element={<TermsOfService />} />
        <Route path="/support" element={<Support />} />
        <Route
          path="/cancellationrefundpolicy"
          element={<CancellationRefundPolicy />}
        />
        <Route path="/AdminLogin" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route path="/admin-blogs" element={<AdminBlogsPanel />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/admin-contacts" element={<AdminContacts />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/admin-appointments" element={<AdminAppointments />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/admin-plan" element={<AdminPlan />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/admin-specialOffer" element={<AdminSpecialOffers />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route
            path="/admin-updateCredentials"
            element={<UpdateAdminCredentials />}
          />
        </Route>
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
