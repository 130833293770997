import React, { useState } from "react";
import "animate.css";
import { AiOutlineClose, AiOutlineLeft, AiOutlineRight } from "react-icons/ai"; // Import icons

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
    "/img/Gallery/1.jpg", // replace with your image URLs
    "/img/Gallery/2.jpg",
    "/img/Gallery/3.jpg",
    "/img/Gallery/4.jpg",
    "/img/Gallery/5.jpg",
    "/img/Gallery/6.jpg",
    "/img/Gallery/7.jpg",
    "/img/Gallery/8.jpg",
    "/img/Gallery/9.jpg",
    "/img/Gallery/10.jpg",
    "/img/Gallery/11.jpg",
    "/img/Gallery/12.jpg",
    "/img/Gallery/13.png",
    "/img/Gallery/14.png",
    "/img/Gallery/15.png",
  ];

  // Open modal with specific image
  const openModal = (index) => {
    setSelectedImage(images[index]);
    setCurrentIndex(index);
  };

  // Close modal
  const closeModal = () => {
    setSelectedImage(null);
  };

  // Navigate to the next image
  const nextImage = () => {
    const newIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(newIndex);
    setSelectedImage(images[newIndex]);
  };

  // Navigate to the previous image
  const prevImage = () => {
    const newIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(newIndex);
    setSelectedImage(images[newIndex]);
  };

  // Close modal if clicked outside the image
  const handleClickOutside = (e) => {
    if (e.target.id === "modal-background") {
      closeModal();
    }
  };

  return (
    <div className="mt-20 py-12 bg-gray-200">
      <h1 className="text-center text-4xl font-bold mb-4 animate__animated animate__fadeInDown">
        Our Gallery
      </h1>
      <p className="text-center mb-6 text-lg text-gray-600 animate__animated animate__fadeInUp">
        Explore our collection of beautiful images
      </p>
      <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 px-6 mx-auto animate__animated animate__fadeInUp">
        {images.map((image, index) => (
          <div
            key={index}
            className="relative overflow-hidden rounded-lg group transform hover:scale-105 transition-all duration-500 cursor-pointer"
            onClick={() => openModal(index)}
          >
            <img
              src={image}
              alt={`Gallery ${index + 1}`}
              className="w-full h-72 object-cover"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-all duration-500">
              {/* <p className="text-white text-lg font-semibold">
                Gallery Image {index + 1}
              </p> */}
            </div>
          </div>
        ))}
      </div>

      {/* Modal for full-screen image */}
      {selectedImage && (
        <div
          id="modal-background"
          className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center animate__animated animate__fadeIn faster z-50"
          onClick={handleClickOutside}
        >
          <div className="relative">
            <img
              src={selectedImage}
              alt="Selected"
              className="w-[90vw] sm:w-[80vw] md:w-[70vw] h-auto max-h-[90vh] max-w-[90vw] object-contain rounded-lg"
            />
            {/* Close Button */}
            <button
              onClick={closeModal}
              className="absolute top-1 right-1 sm:top-2 sm:right-2 md:top-4 md:right-4 text-white bg-black rounded-full p-1 sm:p-2 text-lg hover:bg-gray-800 transition duration-300"
            >
              <AiOutlineClose size={24} /> {/* Close icon */}
            </button>

            {/* Left Arrow */}
            <button
              onClick={prevImage}
              className="absolute left-1 sm:left-2 md:left-4 top-1/2 transform -translate-y-1/2 text-white bg-black p-1 sm:p-2 rounded-full hover:bg-gray-800 transition duration-300"
            >
              <AiOutlineLeft size={24} /> {/* Left Arrow icon */}
            </button>

            {/* Right Arrow */}
            <button
              onClick={nextImage}
              className="absolute right-1 sm:right-2 md:right-4 top-1/2 transform -translate-y-1/2 text-white bg-black p-1 sm:p-2 rounded-full hover:bg-gray-800 transition duration-300"
            >
              <AiOutlineRight size={24} /> {/* Right Arrow icon */}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
