import React from "react";

const Story = () => {
  return (
    <section className="text-gray-600 body-font mt-6">
      <div className="container px-5 py-4 mx-auto">
        <div className="flex flex-col text-center w-full mb-10">
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
            Our Story
          </h1>
          <h2 className="text-sm font-medium title-font text-gray-900 md:mx-32">
            Welcome to Dietician Priyanka, your partner in achieving optimal
            health through personalized coaching. Our certified Dietician
            Priyanka is here to guide you on your weight loss journey.
          </h2>
        </div>

        <div className="flex max-[640px]:flex-col-reverse flex-row-reverse justify-between">
          <div className="max-[640px]:p-8 flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:px-8">
            <div className="w-full sm:p-4 px-4 mb-6">
              <h1 className="title-font font-medium text-xl mb-6 text-gray-900 ">
                <span className="border-b-2 border-[#0A7CEA]">
                  {" "}
                  Inspiring Transformations Story
                </span>{" "}
              </h1>
              <div className="leading-relaxed">
                Dietician Priyanka continues to empower individuals to transform
                their lives through personalized dietician coaching. With an
                expanding client base and a growing team of experts, we remain
                committed to our goal of helping people lose weight, improve
                their health, and lead happier, more fulfilling lives. Our
                journey of inspiring transformations continues, one client at a
                time.
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
            <img
              className="object-cover object-center w-full h-full max-[640px]:border-b-8 min-[640px]:border-r-8 border-[#0A7CEA]"
              src="/img/Story/1.jpg"
              alt="stats"
            />
          </div>
        </div>

        <div className="max-[640px]:flex-col-reverse flex flex-wrap justify-between">
          <div className="max-[640px]:p-8 flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:px-8">
            <div className="w-full sm:p-4 px-4 mb-6">
              <h1 className="title-font font-medium text-xl mb-6 text-gray-900text-left sm:text-right">
                <span className="border-b-2 border-[#0A7CEA]">
                  Recognition and Accolades Story
                </span>
              </h1>
              <div className="leading-relaxedtext-left sm:text-right">
                Dietician Priyanka received industry recognition for its
                excellence in personalized dietician coaching. Our innovative
                approach and dedication to client success earned us accolades
                and solidified our position as a leading provider in the field.
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
            <img
              className="object-cover object-center w-full h-full max-[640px]:border-b-8 min-[640px]:border-l-8 border-[#0A7CEA]"
              src="/img/plate.png"
              alt="plate"
            />
          </div>
        </div>

        <div className="flex max-[640px]:flex-col-reverse flex-row-reverse justify-between">
          <div className="max-[640px]:p-8 flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:px-8">
            <div className="w-full sm:p-4 px-4 mb-6">
              <h1 className="title-font font-medium text-xl mb-6 text-gray-900 ">
                <span className="border-b-2 border-[#0A7CEA]">
                  {" "}
                  Continued Growth Story
                </span>{" "}
              </h1>
              <div className="leading-relaxed">
                Dietician Priyanka celebrated serving over 5,000 clients, a
                testament to our commitment to helping individuals achieve their
                health and weight loss goals. This milestone highlighted the
                positive impact we have made on the lives of thousands of
                people.
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
            <img
              className="object-cover object-center w-full h-full max-[640px]:border-b-8 min-[640px]:border-r-8 border-[#0A7CEA]"
              src="/img/priyanka.png"
              alt="timeing info"
            />
          </div>
        </div>

        <div className="max-[640px]:flex-col-reverse flex flex-wrap justify-between">
          <div className="max-[640px]:p-8 flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:px-8">
            <div className="w-full sm:p-4 px-4 mb-6">
              <h1 className="title-font font-medium text-xl mb-6 text-gray-900text-left sm:text-right">
                <span className="border-b-2 border-[#0A7CEA]">
                  {" "}
                  Collaborating for Success Story
                </span>{" "}
              </h1>
              <div className="leading-relaxedtext-left sm:text-right">
                Dietician Priyanka established partnerships with renowned health
                professionals, including Dietician Priyanka, dietitians, and
                wellness experts. These collaborations allowed us to incorporate
                diverse perspectives and expertise, ensuring the highest level
                of guidance for our clients.{" "}
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
            <img
              className="object-cover object-center w-full h-full max-[640px]:border-b-8 min-[640px]:border-l-8 border-[#0A7CEA]"
              src="/img/Story/3.jpg"
              alt="stats"
            />
          </div>
        </div>
        <div className="flex max-[640px]:flex-col-reverse flex-row-reverse justify-between">
          <div className="max-[640px]:p-8 flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:px-8">
            <div className="w-full sm:p-4 px-4 mb-6">
              <h1 className="title-font font-medium text-xl mb-6 text-gray-900 ">
                <span className="border-b-2 border-[#0A7CEA]">
                  {" "}
                  Enhanced Support Story
                </span>{" "}
              </h1>
              <div className="leading-relaxed">
                In response to the growing demand for personalized nutrition
                coaching, Dietician Priyanka expanded its team of qualified
                Dietician Priyanka and dietitians. This milestone enabled us to
                provide even more individualized care and support to our
                clients.
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
            <img
              className="object-cover object-center w-full h-full max-[640px]:border-b-8 min-[640px]:border-r-8 border-[#0A7CEA]"
              src="/img/Story/4.jpg"
              alt="stats"
            />
          </div>
        </div>

        <div className="max-[640px]:flex-col-reverse flex flex-wrap justify-between">
          <div className="max-[640px]:p-8 flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:px-8">
            <div className="w-full sm:p-4 px-4 mb-6">
              <h1 className="title-font font-medium text-xl mb-6 text-gray-900text-left sm:text-right">
                <span className="border-b-2 border-[#0A7CEA]">
                  {" "}
                  Innovating for Clients Story
                </span>{" "}
              </h1>
              <div className="leading-relaxedtext-left sm:text-right">
                Dietician Priyanka introduced a mobile app, revolutionizing the
                way clients engage with their personalized dietician plans. The
                app allowed for easy tracking of progress, access to resources,
                and seamless communication with their dedicated dietician
                coaches.
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
            <img
              className="object-cover object-center w-full h-full max-[640px]:border-b-8 min-[640px]:border-l-8 border-[#0A7CEA]"
              src="/img/Story/5.png"
              alt="stats"
            />
          </div>
        </div>

        <div className="flex max-[640px]:flex-col-reverse flex-row-reverse justify-between">
          <div className="max-[640px]:p-8 flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:px-8">
            <div className="w-full sm:p-4 px-4 mb-6">
              <h1 className="title-font font-medium text-xl mb-6 text-gray-900 ">
                <span className="border-b-2 border-[#0A7CEA]">
                  {" "}
                  Research and Expertise Story
                </span>{" "}
              </h1>
              <div className="leading-relaxed">
                After extensive research and collaborating with nutrition
                experts, Sarah Mitchell and her team developed a comprehensive
                program rooted in scientific knowledge and evidence-based
                strategies. This milestone marked the establishment of Dietician
                Priyanka as a trusted source of personalized nutrition coaching.
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
            <img
              className="object-cover object-center w-full h-full max-[640px]:border-b-8 min-[640px]:border-r-8 border-[#0A7CEA]"
              src="/img/Story/6.png"
              alt="stats"
            />
          </div>
        </div>

        <div className="max-[640px]:flex-col-reverse flex flex-wrap justify-between">
          <div className="max-[640px]:p-8 flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:px-8">
            <div className="w-full sm:p-4 px-4 mb-6">
              <h1 className="title-font font-medium text-xl mb-6 text-gray-900text-left sm:text-right">
                <span className="border-b-2 border-[#0A7CEA]">
                  {" "}
                  The Inception Story
                </span>{" "}
              </h1>
              <div className="leading-relaxedtext-left sm:text-right">
                On this day, Dietician Priyanka was born with the vision of
                helping individuals achieve their weight loss and health goals
                through personalized dietician coaching. The founder, Sarah
                Mitchell, recognized the need for a sustainable and effective
                approach to healthy living, and thus began the journey of
                Dietician Priyanka.
              </div>
            </div>
          </div>
          <div className="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
            <img
              className="object-cover object-center w-full h-full max-[640px]:border-b-8 min-[640px]:border-l-8 border-[#0A7CEA]"
              src="/img/programs/p11.jpg"
              alt="stats"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Story;
