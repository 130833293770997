import React from "react";
import { Link } from "react-router-dom";
import { FaRegSmile, FaCalendarAlt } from "react-icons/fa";

const Hero = () => {
  return (
    <div className="relative mt-28 h-auto overflow-hidden bg-gradient-to-r from-blue-500 to-[#F3F4F6] py-4">
      {/* <div className="relative mt-28 h-auto overflow-hidden bg-gradient-to-r from-[#F3F4F6] to-blue-500 "> */}
      <section className="relative body-font text-black">
        <div className="relative container mx-auto flex md:flex-row flex-col items-center">
          <div className="lg:max-w-lg lg:w-full md:w-2/3 w-4/6 m-2 animate__animated animate__fadeInLeft">
            <img
              className="object-cover object-center left-0 rounded-lg shadow-lg max-h-[36rem] md:ml-12 transition-transform transform hover:scale-105"
              alt="hero"
              src="1.jpg"
            />
          </div>
          <div className="lg:flex-grow md:w-full lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center mx-2 sm:mx-6 animate__animated animate__fadeInRight">
            <h1 className="title-font lg:text-5xl md:text-5xl sm:text-4xl text-2xl mb-4 font-bold drop-shadow-lg">
              Personalized Nutrition Coach
            </h1>
            <p className="text-medium sm:text-lg lg:text-xl mb-8 leading-relaxed text-justify text-gray-800">
              Welcome to Dietician Priyanka's Healthcare! With over a decade of
              experience, Priyanka specializes in creating personalized
              nutrition plans that help you achieve your health goals. Trust in
              her expertise to guide you towards a healthier, happier lifestyle.
              Let's embark on your wellness journey together!
            </p>
            <div className="flex gap-2">
              <Link to="pricing">
                <button className="inline-flex py-3 px-5 text-white rounded-lg items-center bg-[#0D4372] hover:bg-[#2563EB]  shadow-lg transition-transform transform hover:scale-105">
                  Get Started Today
                </button>
              </Link>
              <Link to="about">
                <button className="bg-gray-300 inline-flex py-3 px-5 rounded-lg items-center  hover:bg-gray-200  shadow-lg transition-transform transform hover:scale-105">
                  Learn more
                </button>
              </Link>
            </div>
            <div className="flex w-full my-10">
              <div className="flex -space-x-4 rtl:space-x-reverse">
                <Link
                  to="pricing"
                  className="flex items-center justify-center text-xl max-[400px]:text-md font-medium text-white drop-shadow-lg"
                  style={{ marginLeft: "0.5rem" }}
                >
                  <FaRegSmile className="mr-2" /> 1000+ Happy Customers
                </Link>
              </div>
            </div>
            <div className="flex items-center justify-center gap-4">
              <aside className="bg-white p-4 rounded-lg shadow-lg hover:shadow-2xl transition-transform transform hover:scale-105 ease-in-out duration-300 flex flex-col items-center text-center">
                <Link to="/appointment" className="w-full">
                  <h3 className="text-md lg:text-xl font-semibold text-gray-800 mb-2 transition-colors duration-300 hover:text-blue-600">
                    <FaCalendarAlt className="inline-block mr-2" /> Book an
                    Appointment
                  </h3>
                </Link>
              </aside>
              <aside className="bg-white p-4 rounded-lg shadow-lg hover:shadow-2xl transition-transform transform hover:scale-105 ease-in-out duration-300 flex flex-col items-center text-center">
                <Link to="/pricing" className="w-full">
                  <h3 className="text-md lg:text-xl font-semibold text-gray-800 mb-2 transition-colors duration-300 hover:text-blue-600">
                    <FaRegSmile className="inline-block mr-2" /> View Plans
                  </h3>
                </Link>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
